<template>
  <div class="card">
    <div title="Akib Khan" class="img-wrapper" :style="{ 'background-image': `url(${myPhoto})` }"></div>
    <div class="overlay">
      <header>
        <h2 title="Akib Khan">Akib Khan</h2>
      </header>
      <p class="bio">
        Hi there!
        <br />I design and build Softwares
      </p>

      <p class="contact">
        <a title="Email" href="mailto:contact@akibrk.com">
          <icon name="mdiEmail"></icon>
        </a>
        <a title="Twitter" href="https://twitter.com/akibrk_" target="_blank">
          <icon name="mdiTwitter"></icon>
        </a>
        <a title="LinkedIn" href="https://linkedin.com/in/akibrk" target="_blank">
          <icon name="mdiLinkedin"></icon>
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileCard',
  props: {
    photoName: {
      type: String,
      default: 'akib_22.jpg',
    },
  },
  computed: {
    myPhoto(): string {
      return require(`@/assets/photos/${this.photoName}`);
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
.card {
  max-width: 100%;
  width: 23rem;
  overflow: hidden;
  .img-wrapper {
    background-repeat: no-repeat;
    background-clip: content-box;
    background-position: center;

    background-size: cover;
    @extend .primary-border;
    border-radius: $primary-radius;
    border-bottom: 0;
    height: 25rem;
    position: relative;
    z-index: -2;
  }

  .overlay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    $border-top-rad: 2.5rem;
    border: none;
    z-index: 20;
    margin-top: -$border-top-rad;
    min-height: 20rem;
    border-top-left-radius: $border-top-rad;
    border-top-right-radius: $border-top-rad;
    background-color: white;
    header {
      display: block;
      padding: 1rem;
      h2 {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .bio {
      border: 0.15rem solid black;
      border-left: none;
      border-right: none;
      margin: 0 2rem;
      padding-top: 2rem;
      padding-bottom: 2rem;

      text-align: center;
      font-weight: bold;
    }
    .contact {
      text-align: center;
      margin: 2rem;
      a {
        margin: 0 1rem;
      }
    }
  }
}
</style>
