<template>
    <a v-if="showBack" @click.prevent="goBack" href=".">
        <icon name="mdiArrowLeft"></icon>
    </a>
    <router-link v-if="showHome" to="/">
        <icon name="mdiHome"></icon>
    </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: "Navigation",
    props: {
        showBack: {
            type: Boolean,
            required: false,
            default: true
        },
        showHome: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        }
    }
});
</script>

<style lang="scss" scoped>
</style>